exports.components = {
  "component---1359300468-ze-artificiali-sbagliano-ancora-le-scritte-nelle-immagini-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-post.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/posts/it_IT/perche-le-intelligenze-artificiali-sbagliano-ancora-le-scritte-nelle-immagini/perche-le-intelligenze-artificiali-sbagliano-ancora-le-scritte-nelle-immagini.md" /* webpackChunkName: "component---1359300468-ze-artificiali-sbagliano-ancora-le-scritte-nelle-immagini-md" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---themes-dry-mdx-src-pages-404-js": () => import("./../../../../../themes/dry-mdx/src/pages/404.js" /* webpackChunkName: "component---themes-dry-mdx-src-pages-404-js" */),
  "component---themes-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-intelligenza-artificiale-intelligenza-artificale-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-category.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/categories/it_IT/intelligenza-artificiale/intelligenza-artificale.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-category-js-content-file-path-contents-categories-it-it-intelligenza-artificiale-intelligenza-artificale-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-homepage-js-content-file-path-contents-pages-it-it-homepage-homepage-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page-homepage.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/pages/it_IT/homepage/homepage.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-homepage-js-content-file-path-contents-pages-it-it-homepage-homepage-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-chi-siamo-chi-siamo-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/pages/it_IT/chi-siamo/chi-siamo.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-chi-siamo-chi-siamo-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-contatti-contatti-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/pages/it_IT/contatti/contatti.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-contatti-contatti-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-privacy-e-cookie-policy-privacy-e-cookie-policy-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/pages/it_IT/privacy-e-cookie-policy/privacy-e-cookie-policy.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-privacy-e-cookie-policy-privacy-e-cookie-policy-md" */),
  "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-termini-e-condizioni-termini-e-condizioni-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-page.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/pages/it_IT/termini-e-condizioni/termini-e-condizioni.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-page-js-content-file-path-contents-pages-it-it-termini-e-condizioni-termini-e-condizioni-md" */),
  "component---themes-dry-mdx-src-templates-mdx-tag-js-content-file-path-contents-tags-it-it-innovazione-il-mio-primo-tag-md": () => import("./../../../../../themes/dry-mdx/src/templates/mdx-tag.js?__contentFilePath=/Users/simonemoro/Code/bufox/gatsby/sites/attracco.it/contents/tags/it_IT/innovazione/il-mio-primo-tag.md" /* webpackChunkName: "component---themes-dry-mdx-src-templates-mdx-tag-js-content-file-path-contents-tags-it-it-innovazione-il-mio-primo-tag-md" */)
}

